 // ProductsPage.js

 import React, { useState, useEffect } from 'react';
 import { useNavigate } from 'react-router-dom';
 import axiosBaseURL from './httpCommon';
 import './ProductsPage.css'; // CSS file for styling the page
 import BoltHeader from './BoltHeader';
 import Boltfooter from './Footer';
 import CourseTimeline from './CourseTimeline';
 

 //Training cards images
 import iot_and_ml_thumbnail from './images/iot_and_ml_thumbnail.png';
 import Ai_Training_thumbnail from './images/Ai_Training_thumbnail.png';
 import Web_dev_thumbnail from './images/Web_dev_thumbnail.png';
 import Augmented_reality_thumbnail from './images/Augmented_reality_thumbnail.png';
 import python_programming_thumbnail from './images/python_programming_thumbnail.png';
 import voice_apps_thumbnail from './images/voice_apps_thumbnail.png';
 import img7 from './images/robotics_arduino.png'
 import Advance_iotml_thumbnail from './images/Advance_iotml_thumbnail.png'
 import img9 from './images/placement_preparation.png'
 import logo from './images/Bolt_IoT_Final_Logo.png'
 import ppv_img from './images/ppv_img.jpg'
 import ashwin_img from './images/ashwin_img.jpg'
 import sachin_img from './images/sachin_img.jpg'
 import profile_img from './images/profile_image.png'
 import robot_logo from './images/robot_logo.png'
 import resume_icon from './images/resume_icon.png'
 import Ai_code_debugger_icon from './images/Ai_code_debugger_icon.png'
 import forum_icon from './images/forum_icon.png'
 import questions_icon from './images/questions_icon.png'

 //Internship section images
 import doubt_icon from './images/doubt-icon.png'
 import internship_img from './images/internship_img.png';
 import intern_with_stipend_icon from './images/intern_with_stipend_icon.png';
 import placement_prep_icon from './images/placement_prep_icon.png'
 import guaranteed_int_icon from './images/guaranteed_int_icon.png'
 
 const initialCards = [
   {
     id: 101,
     imageSrc: Ai_Training_thumbnail,
     title: 'Artificial Intelligence Training',
     description: '',
     instructorImage: ppv_img,
     instructorName: 'Pranav Pai Vernekar',
     amount: '$130',
     url: 'artificial_intelligence'
   },
   { 
    id:102,
    imageSrc: iot_and_ml_thumbnail,
    title: 'Online Training on Internet of Things and Machine Learning',
    description: '',
    instructorImage: ppv_img,
    instructorName: 'Pranav Pai Vernekar',
    amount: '$130',
    url: 'iot_and_ml'
   },
   { 
    id:103,
    imageSrc: Web_dev_thumbnail,
    title: 'Web Development Training on HTML, CSS and JavaScript',
    description: '',
    instructorImage: ppv_img,
    instructorName: 'Pranav Pai Vernekar',
    amount: '$350',
    url: 'web_dev'
   },
   { 
    id:104,
    imageSrc: python_programming_thumbnail,
    title: 'Online Training on Python Programming',
    description: '',
    instructorImage: ppv_img,
    instructorName: 'Pranav Pai Vernekar',
    amount: '$350',
    url: 'python'
   },
   { 
     id:105,
     imageSrc: voice_apps_thumbnail,
     title: 'Voice Apps Online Training',
     description: '',
     instructorImage: ashwin_img,
     instructorName: 'Ashwin Salgaocar',
     amount: '$130',
     url:'voice_apps'
   },
   { 
     id:106,
     imageSrc: Augmented_reality_thumbnail,
     title: 'Augmented Reality Training',
     description: '',
     instructorImage: sachin_img,
     instructorName: 'Sachin Soni',
     amount: '$130',
     url:'augmented_reality'
   },
   { 
     id:107,
     imageSrc: Advance_iotml_thumbnail,
     title: 'Advanced IoT and ML Training',
     description: '',
     instructorImage: sachin_img,
     instructorName: 'Sachin Soni',
     amount: '$350',
     url:'advanced_iot_ml'
   },
   { 
     id:108,
     imageSrc: img9,
     title: 'Placement Preparation Training',
     description: '',
     instructorImage: ppv_img,
     instructorName: 'Pranav Pai Vernekar',
     amount: '$350',
     url:'placement_preparation'
   },
   {
    id:109,
    imageSrc: img7,
    title: 'Online Training on Robotics and Arduino',
    description: '',
    instructorImage: ppv_img,
    instructorName: 'Pranav Pai Vernekar',
    amount: '$350',
    url:'robotics_and_arduino'
   },
   {
     id: 110,
     imageSrc: Ai_Training_thumbnail,
     title: 'Artificial Intelligence Training from IIT Kanpur',
     description: '',
     instructorImage: ppv_img,
     instructorName: 'Pranav Pai Vernekar',
     amount: '$130',
     url: 'artificial_intelligence_iitkanpur'
   },
   
 ];

 const InternshipCard = ({ imageSrc, heading, message, onClick }) => {
  return (
    <div className="internship_card">
      <div className="internship_card-image-container">
        <img src={imageSrc} alt={heading} className={"internship_card-image"} />
      </div>
      <div className='heading-and-footer'>
      <h3 className="internship_card-heading">{heading}</h3>
      <div className="internship_card-footer">
        <h4 className="internship_card-message">{message}</h4>
        </div>
        <button className="start-training" id="start-now-button" onClick={onClick}>Start Now</button>
      </div>

    </div>
  );
};

const DoubtResolutionCard = ({ doubt_image, doubt_heading, doubt_description, onClick }) => {
  return (
    <div className="doubt-resolution-card">
      <div className="doubt-resolution-card-content">
        <div className="doubt-resolution-image-container">
        <img src={doubt_image} alt={doubt_heading} className="doubt-resolution-card-image" />
        </div>
        <div className='doubt-resolution-card-footer'>
          <h2 className="doubt-resolution-card-heading">{doubt_heading}</h2>
          <p className="doubt-resolution-card-description">{doubt_description}</p>
          <button className="doubt-resolution-card-button" onClick={onClick}>Access</button>
        </div>
      </div>
    </div>
  );
};

 const ProductsPage = () => {
   const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
   //const [searchTerm, setSearchTerm] = useState('');
   const [selectedTrainingId, setSelectedTrainingId] = useState(null); // State to store selected training ID
   const [cardsData, setCardsData] = useState(initialCards);
   const [trainings_enrolled, setTrainingsEnrolled] =useState([]);
   const [noTrainingsMessage, setNoTrainingsMessage] = useState(null);
   const [loggedin, setLoggedin] = useState(null); // State to store login status
   const [userData, setUserData] = useState(null); // State to store user data
   const [internshipsData, setInternshipsData] = useState(false);
   const [userName, setUserName] = useState("");
   const [showApiKey, setShowApikey] = useState(false);

   const navigate = useNavigate();

   useEffect(() => {
   fetchTrainings();
   }, [])

   const fetchTrainings = () => {
     axiosBaseURL.get(`/trainings/`)
     .then((response) => {
       setLoggedin(true);
       const data = response.data
       console.log(data)
       setUserData(data.data); // Store user data in state
       const { name } = response.data; // Extract the name from the response data
       setUserName(data.data.name); // Set the username in the state
       window.Produktly.identifyUser(data.data.email, {
        email: data.data.email,
       });
       // No trainings Message
       if (data.data.trainings_enrolled.length === 0){
        const course_url = window.location.href;
       setNoTrainingsMessage(
        <div dangerouslySetInnerHTML={{ __html: `
        <div className='no-trainings-message'>
        <h1 id='oops-title'>OOPS!</h1>
        <div class="para-with-image">
        <p id='para-1'>It looks like you don't have any training access right now.</p>
        <img src="${robot_logo}" alt="robot-icon" className="robot-logo" id='robot-image' />
        </div>
        <p id='para-2'>For COD payment, training access will be available 2 days after delivery.<br></br>
        If you have already made the payment for the order, training access is not displayed for your ID User123@gmail.com, please share a screenshot of our WhatsApp.</p>
        <a href="https://api.whatsapp.com/send?phone=+919545837126&text=I+got+the+number+from+${course_url}.+My+training+access+is+not+showing." target="_blank">
        <button class="whatsapp-button">WhatsApp Me</button>
        </a>
        </div>
        ` }} />
       );
       }
       setTrainingsEnrolled(data.data.trainings_enrolled);
       for (let i = 0; i < data.data.trainings_enrolled.length; i++) {
           document.getElementById(data.data.trainings_enrolled[i]).style.display = "block";
           if(data.data.trainings_enrolled[i]=="Artificial Intelligence Training")
           {
            setShowApikey(true);
           }
       }
       if(data.data.internship_unlocked == false){
        setInternshipsData(true)
       }
       
     })
     .catch((error) => {
       //unable to authenticate
       console.log("Redirecting to login due to below erorr");
       console.log(error)
       setLoggedin(false);
       navigate('/');
     });
 }

   const handleProfileClick = () => {
     setProfileDropdownOpen(!isProfileDropdownOpen);
   };

   const handleLogout = () => {
     // Assuming you're sending a POST request to the login URL
     axiosBaseURL.get('/logout')
       .then(response => {
         // Handle the response if needed
           navigate('/')
         if (response === "200")
         {
           navigate('/')
         }
       })
       .catch(error => {
         // Handle errors
         console.error('Error during logout:', error);
         navigate('/')
       });
   };

   const handleLogoClick = () => {
    window.location.reload();
   };

   const handleCardClick = (id, training_name) => {
    setSelectedTrainingId(id);
    const url = `/trainings/${encodeURIComponent(training_name)}`;
    navigate(url, { state: { id, email: userData.email } });
  };

   return (
     <div className="products-page">
      <BoltHeader showApiKey={showApiKey}/>
      <CourseTimeline/>
      {/* Training Cards Container */}
      <div className="card-container">
       {noTrainingsMessage ? (
         <div className="no-trainings-message">{noTrainingsMessage}</div>
       ) : (
         cardsData.map((card, index) => (
           <div className="card" style={{display:"none"}} id={card.title} key={index}>
             <div className="card-image-container">
               <img src={card.imageSrc} alt={`Card ${index}`} className="card-image" />
             </div>
             <div className="card-content">
             <h3 className="card-heading">{card.title}</h3>
             {/* <p className="card-description">{card.description}</p> */}
             </div>
             <div className="card-footer">
                <div className="start-training-button" id='start-training-button'>
                 <button className="start-training" onClick={() => handleCardClick(card.id, card.url)}>Start Training</button>
               </div> 
             </div>
           </div>
         ))
       )}
     </div>       

      {/* Separator Text */}
      <div className='separator-text-container'>
        <h1 className='separator-heading'>INTERNSHIP OPPORTUNITIES</h1>
        <p className='separator-sub-text'>Grab the opportunities don't lose out</p>
      </div>

     {/* Internships Cards Container */}
     <div className='internship_card-container'>
        {/* Card 1: Placement Preparation training */}
        <InternshipCard
          imageSrc={placement_prep_icon}
          heading="Placement Preparation"
          message="Click here for tips for Internship preparation (No additional fee)"
          onClick={() => (handleCardClick(108, "placement_preparation"))}
        />

        {/* Card 2: Internships with stipend */}
        <InternshipCard
          imageSrc={intern_with_stipend_icon}
          heading="Internships with stipend"
          message="Click here to apply for the Internships with Stipend (No additional fee)"
          onClick={() => (navigate('/internshipwithstipend'))}
        />

        {/* Card 3: Assured Internships */}
        <InternshipCard id="assured-intern-card"
          imageSrc={guaranteed_int_icon}
          heading="Guaranteed Internship by Bolt IoT  (Without Stipend)"
          message="Click here to apply for the Internship by Bolt IoT (No additional fee)"
          onClick={() => (navigate('/assuredinternships'))}
        />
      </div>

      {/* Get Offer Letter Section*/}
    <div className='get-offer-letter-container'>
       <div className='offer-letter-content'>
          <div className='separator-text-container' id="offer-letter-heading-detail">
            <h1 className='separator-heading'>SPEED UP INTERNSHIP PROCESS</h1>
            <p className='separator-sub-text'>By getting offer letter and AI Resume Optimizer </p>
          </div>
          <div className='offer-letter-image-container'>
          <img src={resume_icon} alt="image" className="resume-image" />
          </div>
          </div>
          <div className="buttons-container" id="offer-letter-buttons-container">
            <button className="internships_button" onClick={() => window.open("https://info.boltiot.com/offerletterrequest", "_blank")}>Get Offer Letter</button>
            <button className="internships_button" onClick={() => window.open("https://info.boltiot.com/resumeoptimizer", "_blank")}>AI Resume Optimizer</button>
          </div>
    </div>

        {/* Doubts Resolution */}
        <div className='doubts-resolution-container'>
          <div className='doubts-resolution-content'>
            <div className='separator-text-container' id="doubt-heading-detail">
              <h1 className='separator-heading'id='doubt-main-heading'>DOUBT RESOLUTION</h1>
              <p className='separator-sub-text' id='doubt-sub-text'>
                <p id='sentence-1'>Not got resolved?</p><p id='sentence-2'>Write to <a href="mailto:support@boltiot.com">support@boltiot.com</a> to escalate</p>
              </p>
            </div>
              {/* Doubts Resolution cards */}
              {/* card 1: AI Code debugger */}
            <div className='doubts-resolution-card-div'>
              <DoubtResolutionCard
                doubt_image={Ai_code_debugger_icon}
                doubt_heading="AI Code Debugger"
                doubt_description="24 X 7"
                onClick={() => (navigate('/AICodeDebugger'))}
                />
            {/* card 2: Forum */}
              <DoubtResolutionCard
                doubt_image={forum_icon}
                doubt_heading="Forum"
                doubt_description="Within one day"
                onClick={() => window.open("https://forum.boltiot.com/", "_blank")}
                />
            {/* card 3: Internship FAQ */}
              <DoubtResolutionCard
                doubt_image={questions_icon}
                doubt_heading="Internship FAQ"
                doubt_description="24 X 7"
                onClick={() => (navigate('/internshipFAQ'))}
                />
                </div>
          </div>
        </div>
       <Boltfooter/>
 
     </div>
   );
 };

 export default ProductsPage;
